/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Akreditace"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center" name={"0hkj07uppre"} style={{"paddingTop":133,"paddingBottom":28}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"marginTop":0,"paddingTop":0}}>
              
              <Title className="title-box title-box--center" content={"<span style=\"text-decoration-line: underline;\">AKREDITACE</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"l6ek8v35jum"} style={{"marginTop":0,"paddingTop":0,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column name={"cqhhhcj1j7w"} style={{"paddingTop":0,"paddingBottom":0}} layout={"l8"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26649/b12d6cb3b4cf4ef7a9656d9fa25230b2_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/26649/b12d6cb3b4cf4ef7a9656d9fa25230b2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26649/b12d6cb3b4cf4ef7a9656d9fa25230b2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26649/b12d6cb3b4cf4ef7a9656d9fa25230b2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26649/b12d6cb3b4cf4ef7a9656d9fa25230b2_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26649/d3d7deade74144aa86e5073a794747e8_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/26649/d3d7deade74144aa86e5073a794747e8_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26649/d3d7deade74144aa86e5073a794747e8_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26649/d3d7deade74144aa86e5073a794747e8_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26649/d3d7deade74144aa86e5073a794747e8_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26649/7064b64710954480b9f6ba6256ba6c82_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/26649/7064b64710954480b9f6ba6256ba6c82_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26649/7064b64710954480b9f6ba6256ba6c82_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26649/7064b64710954480b9f6ba6256ba6c82_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26649/7064b64710954480b9f6ba6256ba6c82_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26649/cb0266a2d9c74682a32b42c584302c8c_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/26649/cb0266a2d9c74682a32b42c584302c8c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26649/cb0266a2d9c74682a32b42c584302c8c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26649/cb0266a2d9c74682a32b42c584302c8c_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26649/cb0266a2d9c74682a32b42c584302c8c_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26649/b07c2e4a1bf04e3f8657045261de1c12_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/26649/b07c2e4a1bf04e3f8657045261de1c12_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26649/b07c2e4a1bf04e3f8657045261de1c12_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26649/b07c2e4a1bf04e3f8657045261de1c12_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26649/b07c2e4a1bf04e3f8657045261de1c12_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/26649/52c968d8176d4e78a8fede309ab37225_s=860x_.jpg"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/26649/52c968d8176d4e78a8fede309ab37225_s=350x_.jpg 350w, https://cdn.swbpg.com/t/26649/52c968d8176d4e78a8fede309ab37225_s=660x_.jpg 660w, https://cdn.swbpg.com/t/26649/52c968d8176d4e78a8fede309ab37225_s=860x_.jpg 860w, https://cdn.swbpg.com/t/26649/52c968d8176d4e78a8fede309ab37225_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":26,"paddingTop":0,"paddingLeft":103,"paddingRight":24,"paddingBottom":0,"backgroundColor":"rgba(160, 160, 160, 1)"}} name={"o-nas"}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":606}} content={"<br><span style=\"color: var(--black); font-weight: bold;\">Vodohospodářská laboratoř Říčany, s. r. o.\n<br>Kolovratská 1476,251 01 Říčany\n<br>tel.: 323602539\nmobil: 602363180\n<br>e-mail: laboratorkopecna@seznam.cz\n<br><br>&nbsp;IČO 28393601\nDIČ CZ28393601</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":495}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold; text-decoration-line: underline;\">Příjem vzorků vody:\n</span><br>Pondělí a úterý: 8.00 – 15.30 hod.<br>Středa: 8.00 - 11.00 hod.\n<br><br>&nbsp;<span style=\"text-decoration-line: underline; font-weight: bold;\">Pracovní doba laboratoře:\n</span><br>Pondělí až čtvrtek: 8.00 - 15.30 hod. (po dohodě do 18 hod.)<br>Pátek: 8.00 - 14.00 hod.\n<br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}